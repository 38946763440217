<template>
  <div id="pages">
    <div class="row">
      <div class="col-lg-3 col-md-6 d-flex align-items-stretch" v-for="prod in products" :key="prod.id" data-aos="fade-up" data-aos-delay="400">
        <div class="member" style="width: 270px">
          <div class="member-img" style="height: 230px">
            <img v-bind:src="'' + prod.image" class="img-fluid" alt="picture">
          </div>
          <div class="member-info">
            <h4><a v-bind:href="'/products/details/' + prod.id">{{ prod.name }}</a></h4>
            <span>{{ prod.brand }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PagesBase",
  props:{
    products: Array,
  }
}
</script>

<style scoped>

</style>