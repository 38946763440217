<template>
<main id="main" data-aos="fade-up" class="mt-5">
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact mt-5">
    <div class="container" data-aos="fade-up">

      <div class="section-title mt-5">
        <h3><span>Контакт</span></h3>
        <p>Како може да нé контактирате</p>
      </div>

      <div class="row" data-aos="fade-up" data-aos-delay="100">
        <div class="col-lg-6">
          <div class="info-box mb-4">
            <i class="bx bx-map"></i>
            <h3>Нашата адреса</h3>
            <p>Народен Фронт 13-5<br>
              1000 Скoпје, Македонија</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box  mb-4">
            <i class="bx bx-envelope"></i>
            <h3>Испратете е-маил</h3>
            <p>coo@videoalarm.com.mk</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box  mb-4">
            <i class="bx bx-phone-call"></i>
            <h3>Јавете се</h3>
            <p><a href="tel:+38923121314">+389 2 312 1314</a></p>
          </div>
        </div>

      </div>

      <div class="row" data-aos="fade-up" data-aos-delay="100">

        <div class="col-lg-6 ">
          <iframe class="mb-4 mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d741.328887415796!2d21.41761882925232!3d41.99350299543765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13541437cef452fb%3A0x3b09f513a380aa59!2sVideo%20Alarm!5e0!3m2!1smk!2smk!4v1603197400354!5m2!1smk!2smk" frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe>
        </div>

        <div id="message" class="col-lg-6">
          <div class="alert alert-success alert-dismissible hide" role="alert" hidden>
            <span class="bx bx-check"></span> <span>Вашата порака е успешно испратена испратена</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="close"><span class="bx bx-x"></span></button>
          </div>

          <div class="alert alert-danger alert-dismissible hide" role="alert" hidden>
            <span class="bx bx-check"></span> <span>Се случи грешка при испраќање. Сеуште може да не контактирате преку e-mail.</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="close"><span class="bx bx-x"></span></button>
          </div>

          <div class="alert alert-danger alert-dismissible hide" role="alert" hidden>
            <span class="bx bx-check"></span> <span>Се случи грешка при испраќање. Вашата порака содржи линк.</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="close"><span class="bx bx-x"></span></button>
          </div>

          <form action="/mail" method="post" role="form" class="php-email-form">
            <div class="form-row">
              <div class="col form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="Вашето Име" data-rule="minlen:4" required="" oninvalid="this.setCustomValidity('Внесетего вашето име')" oninput="this.setCustomValidity('')" data-msg="Внесетего вашето име" />
                <div class="validate"></div>
              </div>
              <div class="col form-group">
                <input type="email" class="form-control" name="email" id="email" placeholder="Вашата e-mail адреса" data-rule="email" required="" oninvalid="this.setCustomValidity('Внесете ја вашата e-mail адреса')" oninput="this.setCustomValidity('')" data-msg="Внесете ја вашата e-mail адреса" />
                <div class="validate"></div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" name="subject" id="subject" placeholder="Наслов" data-rule="minlen:4" required="" oninvalid="this.setCustomValidity('Внесете наслов на пораката')" oninput="this.setCustomValidity('')" data-msg="Внесете наслов на пораката" />
              <div class="validate"></div>
            </div>
            <div class="form-group">
              <textarea class="form-control" name="message" rows="5" data-rule="required" data-msg="Испратете ни порака" required="" oninvalid="this.setCustomValidity('За што имате прашање?')" oninput="this.setCustomValidity('')" placeholder="Порака"></textarea>
              <div class="validate"></div>
            </div>
            <div class="text-center"><button type="submit">Испрати</button></div>
          </form>
        </div>
      </div>
    </div>
  </section><!-- End Contact Section -->
</main>
</template>

<script>
export default {
  name: "ContactPage",
  mounted() {
    document.title = "Contact"
  }
}
</script>

<style scoped>

</style>