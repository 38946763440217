<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">
    <div class="container" data-aos="zoom-out" data-aos-delay="100">
      <h1>Добредојдовте во Видео Аларм</h1>
      <h2>Над 25 успешно техничко обезбедување низ цела Македонија</h2>
      <div class="d-flex">
        <router-link to="/about" class="btn-get-started scrollto">Дознај повеќе</router-link>
        <!--<a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="venobox btn-watch-video" data-vbtype="video" data-autoplay="true"> Watch Video <i class="icofont-play-alt-2"></i></a>-->
      </div>
    </div>
  </section><!-- End Hero -->
  <section id="about" class="about section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>За Нас</h2>
        <h3>Дознајте повеќе  <span>За нас</span></h3>
        <p>Компанијата “ВИДЕО АЛАРМ” успешно делува и работи на полето на сигурносно-техничката заштита на објекти и отворени простори повеќе од 25 и е една од водечките компании во тој домен во Македонија.</p>
      </div>

      <div class="row">
        <div class="col-lg-6" data-aos="zoom-out" data-aos-delay="100">
          <img src="../assets/img/about.jpg" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
          <p>
            Како специјализирана компанија чија основна и единствена дејност е проектирање, набавка и изведба на системи за Видео Надзор- локален и далечински , ПП Системи, системи за Контрола на Пристап и Евиденција на работно време, системи за Детекција и дојава на провала и кражба и Видео-домофонски и системи за интерфонска комуникација, Автоматски бариери за возила и автоматика за врати, изведуваме и во состојба сме да понудиме комплетни решенија за истите.
          </p>
          <p>
            Во своето долгогодишно работење нашата компанија се одликува со професионална и брза изведба дадените задачи и договорените работи. Сето тоа е допринес од постојаната опременост на компанијата со потребните компоненти за системите - како постојан лагер на опремата за системите што ги нуди и располагањето на компанијата со стручен кадар од 8 вработени лица од кои 7 лица се технички оспособени и се директно вклучени во проектирањето и изведбата на системите.
          </p>
        </div>
      </div>

    </div>
  </section><!-- End About Section -->

    <!-- ======= Counts Section ======= -->
    <section id="counts" class="counts section-bg">
      <div class="container" data-aos="fade-up">

        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="count-box">
              <i class="icofont-simple-smile"></i>
              <span data-toggle="counter-up"><span>500+</span></span>
              <p>Задоволни клиенти</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
            <div class="count-box">
              <i class="icofont-document-folder"></i>
              <span data-toggle="counter-up"><span>1500+</span></span>
              <p>Реализирани проекти</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div class="count-box">
              <i class="icofont-live-support"></i>
              <span data-toggle="counter-up">365</span>
              <p>Дена поддршка</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div class="count-box">
              <i class="icofont-users-alt-5"></i>
              <span data-toggle="counter-up"><span>8</span></span>
              <p>Вработени</p>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Counts Section -->

    <!-- ======= Clients Section ======= -->
    <section id="clients" class="clients section-bg">
      <div class="container" data-aos="zoom-in">

        <div class="row">

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/client-1.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/client-2.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/client-3.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/client-4.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/client-5.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/kargo.jpg" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/adora.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/amsm.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/autosk.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/flamingo.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/hitech.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/info.png" class="img-fluid" alt="">
          </div>

        </div>

      </div>
    </section>
    <!-- End Clients Section -->

    <!-- ======= Services Section ======= -->
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Сервиси</h2>
          <h3>Срвиси и <span>техничка поддршка</span></h3>
        </div>

        <div class="row">

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <div class="icon"><i class="bx bx-message-rounded-error"></i></div>
              <h4><a href="/support">Често поставени прашања</a></h4>
              <p>Некои од најчесто поставувани прашања</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <div class="icon"><i class="bx bx-lock-open"></i></div>
              <h4><a href="">kosta@videoalarm.com.mk</a></h4>
              <p>Поддршка за контрола на пристап и евиденција на работно време</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <div class="icon"><i class="bx bx-cctv"></i></div>
              <h4><a href="">kire@videoalarm.com.mk</a></h4>
              <p>Поддршка за CCTV и алармни системи</p>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End Services Section -->

    <!-- ======= Team Section ======= -->
    <section id="team" class="team section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Партнери</h2>
          <h3>Копмпании со кои <span>Соработуваме</span></h3>
          <p>Тука се наброени некои од компаниите со кои ние соработуваме.</p>
        </div>

        <div class="row">

          <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <div class="member">
              <div class="member-img">
                <img src="../assets/img/team/crow.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href="https://www.thecrowgroup.com/"><i class="icofont-web"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>CROW</h4>
                <span>Electronic Engineering</span>
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div class="member">
              <div class="member-img">
                <img src="../assets/img/team/milesight.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href="https://www.milesight.com/"><i class="icofont-web"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Milesight</h4>
                <span></span>
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="member">
              <div class="member-img">
                <img src="../assets/img/team/foutec.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href="http://www.foutec.com/"><i class="icofont-web"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Foutec</h4>
                <span>Digital Technology</span>
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div class="member">
              <div class="member-img">
                <img src="../assets/img/team/paili.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href="http://www.pailitech.com/"><i class="icofont-web"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Paili</h4>
                <span>Pailitech</span>
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
            <div class="member">
              <div class="member-img">
                <img src="../assets/img/team/idteck.png" class="img-fluid" alt="">
                <div class="social">
                  <a href="https://www.idteck.com/en/"><i class="icofont-web"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>IDTECK</h4>
                <span></span>
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
            <div class="member">
              <div class="member-img">
                <img src="../assets/img/team/ygs.jpg" class="img-fluid" alt="">
                <div class="social">
                  <a href=""><i class="icofont-web"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>YGS Locks</h4>
                <span></span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Team Section -->
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Контакт</h2>
        <h3><span>Контакт</span></h3>
        <p>Како може да нé контактирате</p>
      </div>

      <div class="row" data-aos="fade-up" data-aos-delay="100">
        <div class="col-lg-6">
          <div class="info-box mb-4">
            <i class="bx bx-map"></i>
            <h3>Нашата адреса</h3>
            <p>Народен Фронт 13-5<br>
              1000 Скoпје, Македонија</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box  mb-4">
            <i class="bx bx-envelope"></i>
            <h3>Испратете е-маил</h3>
            <p>coo@videoalarm.com.mk</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box  mb-4">
            <i class="bx bx-phone-call"></i>
            <h3>Јавете се</h3>
            <p><a href="tel:+38923121314">+389 2 312 1314</a></p>
          </div>
        </div>

      </div>

      <div class="row" data-aos="fade-up" data-aos-delay="100">

        <div class="col-lg-6 ">
          <iframe class="mb-4 mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d741.328887415796!2d21.41761882925232!3d41.99350299543765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13541437cef452fb%3A0x3b09f513a380aa59!2sVideo%20Alarm!5e0!3m2!1smk!2smk!4v1603197400354!5m2!1smk!2smk" frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe>
        </div>

        <div id="message" class="col-lg-6">
          <div class="alert alert-success alert-dismissible hide" role="alert" hidden>
            <span class="bx bx-check"></span> <span>Вашата порака е успешно испратена испратена</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="close"><span class="bx bx-x"></span></button>
          </div>

          <div class="alert alert-danger alert-dismissible hide" role="alert" hidden>
            <span class="bx bx-check"></span> <span>Се случи грешка при испраќање. Сеуште може да не контактирате преку e-mail.</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="close"><span class="bx bx-x"></span></button>
          </div>

          <div class="alert alert-danger alert-dismissible hide" role="alert" hidden>
            <span class="bx bx-check"></span> <span>Се случи грешка при испраќање. Вашата порака содржи линк.</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="close"><span class="bx bx-x"></span></button>
          </div>

          <form action="/mail" method="post" role="form" class="php-email-form">
            <div class="form-row">
              <div class="col form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="Вашето Име" data-rule="minlen:4" required="" oninvalid="this.setCustomValidity('Внесетего вашето име')" oninput="this.setCustomValidity('')" data-msg="Внесетего вашето име" />
                <div class="validate"></div>
              </div>
              <div class="col form-group">
                <input type="email" class="form-control" name="email" id="email" placeholder="Вашата e-mail адреса" data-rule="email" required="" oninvalid="this.setCustomValidity('Внесете ја вашата e-mail адреса')" oninput="this.setCustomValidity('')" data-msg="Внесете ја вашата e-mail адреса" />
                <div class="validate"></div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" name="subject" id="subject" placeholder="Наслов" data-rule="minlen:4" required="" oninvalid="this.setCustomValidity('Внесете наслов на пораката')" oninput="this.setCustomValidity('')" data-msg="Внесете наслов на пораката" />
              <div class="validate"></div>
            </div>
            <div class="form-group">
              <textarea class="form-control" name="message" rows="5" data-rule="required" data-msg="Испратете ни порака" required="" oninvalid="this.setCustomValidity('За што имате прашање?')" oninput="this.setCustomValidity('')" placeholder="Порака"></textarea>
              <div class="validate"></div>
            </div>
            <div class="text-center"><button type="submit">Испрати</button></div>
          </form>
        </div>
      </div>
    </div>
  </section><!-- End Contact Section -->
</template>

<script>
export default {
  name: "HomePage",
  mounted() {
    document.title = "Video Alarm";
  }
}
</script>

<style scoped>

</style>