<template>
  <main id="main" data-aos="fade-up">

    <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>Продукти</h2>
          <ol>
            <li><a href="/">Дома</a></li>
            <li>Продукти</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->
    <div class="container" data-aos="fade-up">
      <section id="featured-services" class="featured-services">
        <div class="container" data-aos="fade-up">
          <div class="row">

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div class="icon"><i class="bx bx-bell"></i></div>
                <h4 class="title"><a href="/products/alarms">Алармин Системи</a></h4>
                <p class="description">Сензори, сирени и централи за алармни системи</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div class="icon"><i class="bx bx-camera-movie"></i></div>
                <h4 class="title"><a href="/products/video">Видео Надзор</a></h4>
                <p class="description">Камери, рекордери и додатои</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div class="icon"><i class="bx bx-lock-open"></i></div>
                <h4 class="title"><a href="/products/access_control">Контрола на пристап</a></h4>
                <p class="description">Контролери, Standalone контролери, читачи, извршни елементи</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div class="icon"><i class="bx bxs-flame"></i></div>
                <h4 class="title"><a href="/products/fire_alarm">Детекција и дојава на пожар</a></h4>
                <p class="description">Противпожарни сензори, сирени и централи</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div class="icon"><i class="bx bx-time-five"></i></div>
                <h4 class="title"><a href="/products/work_time">Евиденција на работно време</a></h4>
                <p class="description">Терминали, читачи и додатоци</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div class="icon"><i class="bx bx-shield"></i></div>
                <h4 class="title"><a href="/products/patrol">Системи за патролна и чуварска служба</a></h4>
                <p class="description">Guard tour системи, следење и контрола преку GSM мрежа</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div class="icon"><i class="bx bx-phone"></i></div>
                <h4 class="title"><a href="/products/doorbell">Домофонија и видеофонија</a></h4>
                <p class="description">Видеофони, домофони и додатоци</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div class="icon"><i class="bx bx-broadcast"></i></div>
                <h4 class="title"><a href="/products/remote_gate">Далечинска контрола и автоматика</a></h4>
                <p class="description">Далечински контролери, рампи, бариери и триподи</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div class="icon"><i class="bx bx-world"></i></div>
                <h4 class="title"><a href="/products/network">Мрежни системи</a></h4>
                <p class="description">Мрежни свичеви, рутери, и POE свичеви</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div class="icon"><i class="bx bx-hotel"></i></div>
                <h4 class="title"><a href="/products/hotel">Хотелски системи</a></h4>
                <p class="description">Хотелски брави, прекинувачи и  контролери</p>
              </div>
            </div>
            <!--
                          <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
                            <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                              <div class="icon"><i class="bx bx-home"></i></div>
                              <h4 class="title"><a href="/products/smarthome">Smart Home системи</a></h4>
                              <p class="description">Контролери и модули за автоматизација на домот</p>
                            </div>
                          </div>
            -->
            <div class="col-md-6 col-lg-3 d-flex align-items-stretch pb-3 mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div class="icon"><i class="bx bx-cog"></i></div>
                <h4 class="title"><a href="/products/cables_other">Кабли и додатоци</a></h4>
                <p class="description">Различни кабли, додатоци, алати и напојни единици</p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  </main><!-- End #main -->
</template>

<script>
export default {
  name: "ProductBasic"
}
</script>

<style scoped>

</style>