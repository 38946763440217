import {createRouter, createWebHistory} from "vue-router";

import LandingPage from "@/views/LandingPage";
import ProductBasic from "@/views/ProductBasic";
import SupportPage from "@/views/SupportPage";
import ProductCategory from "@/components/ProductCategory";
import ProductSubcategory from "@/components/ProductSubcategory";
import AboutPage from "@/components/AboutPage";
import PartnerPage from "@/components/PartnerPage";
import ContactPage from "@/components/ContactPage";
import SearchPage from "@/components/SearchPage";
import DetailsPage from "@/components/DetailsPage";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: LandingPage,
    },
    {
      path: '/about',
      name: 'About',
      component: AboutPage
    },
    {
      path: '/partners',
      name: 'Partners',
      component: PartnerPage
    },
    {
      path: '/contact',
      name: 'Contact',
      component: ContactPage
    },
    {
        path: '/products',
        name: 'Products',
        component: ProductBasic,
    },
    {
        path: '/support',
        name: 'Support',
        component: SupportPage,
    },
    {
        path: '/products/:category',
        name: 'Category',
        component: ProductCategory,
    },
    {
        path: '/products/:category/:sub',
        name: 'Subcategory',
        component: ProductSubcategory
    },
    {
        path: '/search',
        name: 'Search',
        component: SearchPage
    },
    {
        path: '/products/details/:id',
        name: 'Details',
        component: DetailsPage
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), routes,
})
console.log(process.env.BASE_URL)
export default router