<template>
  <header id="header" class="fixed-top">

    <div class="container d-flex align-items-center">
      <!-- <h1 class="logo mr-auto"><a href="index">Video Alarm<span>.</span></a></h1>
      <img src="/assets/img/logolarge.png" alt="">-->
      <!-- Uncomment below if you prefer to use an image logo -->
      <router-link to="/" class="logo mr-auto">
        <img src="@/assets/img/logosmall.png" alt="Logo small" class="d-lg-none">
        <img src="@/assets/img/logolarge.png" alt="Logo large" class="d-none d-lg-block"></router-link>
      <nav class="nav-menu d-none d-lg-block">
        <ul>
          <li><router-link to="/">Дома</router-link></li>
          <li><router-link to="/about">За Нас</router-link></li>
          <li><router-link to="/partners">Партнери</router-link></li>
          <!-- <li><a href="#team">Team</a></li> -->
          <li class="drop-down"><router-link to="/products">Продукти</router-link>
            <ul>
              <li><a href="/products/alarms"><b>Алармни системи</b></a></li>
              <li><a href="/products/video"><b>Видео надзор</b></a></li>
              <li><a href="/products/access_control"><b>Контрола на пристап</b></a></li>
              <li><a href="/products/fire_alarm"><b>Детекција и дојава на пожар</b></a></li>
              <li><a href="/products/work_time"><b>Евиденција на работно време</b></a></li>
              <li><a href="/products/patrol"><b>Системи за патролна и чуварска служба</b></a></li>
              <li><a href="/products/doorbell"><b>Домофонија и видеофонија</b></a></li>
              <li><a href="/products/remote_gate"><b>Далечинска контрола и автоматика</b></a></li>
              <li><a href="/products/network"><b>Мрежни системи</b></a></li>
              <li><a href="/products/hotel"><b>Хотелски сиситеми</b></a></li>
              <!--<li><a href="/products/smarthome"><b>Smart Home системи</b></a></li>-->
              <li><a href="/products/cables_other"><b>Кабли и додатоци</b></a></li>
            </ul>
          </li>
          <li><router-link to="/support">Поддршка</router-link></li>
          <li><router-link to="/contact">Контакт</router-link></li>
        </ul>
      </nav><!-- .nav-menu -->
      <div class="icon">
        <i @click="toggleMobileNav" v-show="mobile" class="far fa-bars" :class="{'icon-active': mobileNav}"></i>
      </div>
      <transition name="mobile-nav" class="position-fixed justify-content-center p-0 h-100 w-100" style="margin-top: 107vh; margin-left: -10vw">
        <ul v-show="mobileNav" class="list-group-item">
          <li class="list-group-item list-group-item-action"><a class="text-dark" href="/products/alarms"><b>Алармни системи</b></a></li>
          <li class="list-group-item list-group-item-action"><a class="text-dark" href="/products/video"><b>Видео надзор</b></a></li>
          <li class="list-group-item list-group-item-action"><a class="text-dark" href="/products/access_control"><b>Контрола на пристап</b></a></li>
          <li class="list-group-item list-group-item-action"><a class="text-dark" href="/products/fire_alarm"><b>Детекција и дојава на пожар</b></a></li>
          <li class="list-group-item list-group-item-action"><a class="text-dark" href="/products/work_time"><b>Евиденција на работно време</b></a></li>
          <li class="list-group-item list-group-item-action"><a class="text-dark" href="/products/patrol"><b>Системи за патролна и чуварска служба</b></a></li>
          <li class="list-group-item list-group-item-action"><a class="text-dark" href="/products/doorbell"><b>Домофонија и видеофонија</b></a></li>
          <li class="list-group-item list-group-item-action"><a class="text-dark" href="/products/remote_gate"><b>Далечинска контрола и автоматика</b></a></li>
          <li class="list-group-item list-group-item-action"><a class="text-dark" href="/products/network"><b>Мрежни системи</b></a></li>
          <li class="list-group-item list-group-item-action"><a class="text-dark" href="/products/hotel"><b>Хотелски сиситеми</b></a></li>
          <!--<li><a href="/products/smarthome"><b>Smart Home системи</b></a></li>-->
          <li class="list-group-item"><a class="text-dark" href="/products/cables_other"><b>Кабли и додатоци</b></a></li>
        </ul>
      </transition>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderBar",
  data(){
    return {
      mobile: false,
      mobileNav: false,
      windowWidth: null

    }
  },
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  methods:{
    toggleMobileNav(){
      this.mobileNav = !this.mobileNav;
    },
    checkScreen(){
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750){
        this.mobile = true;
      }
      else{
        this.mobile = false;
        this.mobileNav = false;
      }
    }
  }
}
</script>

<style scoped>
.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: 500ms ease all;
}
.mobile-nav-enter-from,
.mobile-nav-leave-to{
  transform: translateX(-500px);
}

.mobile-nav-enter-to{
  transform: translateX(0);
}

</style>