<template>
  <main id="main" data-aos="fade-up">

    <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center">
          <h2>Search for: {{keyword}}</h2>
        </div>
      </div>
    </section><!-- End Breadcrumbs -->
    <div class="container-flex pl-5 pr-5 d-flex justify-content-center" data-aos="fade-up">
      <section id="team" class="team section-bg pt-1 pb-1">
        <div class="container" data-aos="fade-up">

          <PagesBase :products = "displayItems"/>

          <ul class="pagination justify-content-center">
            <li class="page-item"><a class="page-link" v-on:click="updatePage(this.currentPage-1)"><i class="bx bx-arrow-to-left"></i></a></li>
            <li class="page-item" v-for="i in maxPages" :key="i">
              <div v-if="checkPage(i)" class="page-link bg-secondary"><a class="text-light" v-on:click="updatePage(i-1)">{{i}}</a></div>
              <div v-else class="page-link"><a class="text-dark" v-on:click="updatePage(i-1)">{{i}}</a></div>
            </li>
            <li class="page-item"><a class="page-link" v-on:click="updatePage(this.currentPage+1)"><i class="bx bx-arrow-to-right"></i></a></li>
          </ul>
        </div>
      </section>
    </div>
  </main><!-- End #main -->
</template>

<script>
import {useRoute} from "vue-router";
import PagesBase from "@/components/PagesBase";

export default {
  name: "SearchPage",
  components: {
    PagesBase
  },
  data() {
    return {
      keyword: '',
      prods: [],
      displayItems: [],
      currentPage: 0,
      maxPages: 1,
      itemsPerPage: 20,
    }
  },
  mounted() {
    const route = useRoute()
    this.keyword = route.query.keyword
    this.getProds()
    document.title = "Search"
  },
  methods: {
    async getProds(){
      const baseUrl = process.env.VUE_APP_BASEURL
      const res = await fetch(baseUrl + "/keyword.php?id=" + this.keyword)
      this.prods = await res.json()
      this.updateDisplayProds()
      this.maxPages = Math.ceil(this.prods.length / this.itemsPerPage)
    },
    updateDisplayProds(){
      this.displayItems = this.prods.slice(this.currentPage * this.itemsPerPage, (this.currentPage * this.itemsPerPage) + this.itemsPerPage);
    },
    updatePage(pageNumber){
      if(pageNumber >= 0 && pageNumber < this.maxPages){
        this.currentPage = pageNumber
        this.updateDisplayProds()
      }
    },
    checkPage(i){
      return i === this.currentPage+1;
    }
  }
}
</script>

<style scoped>

</style>