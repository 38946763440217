<template>
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact">
            <p>
              Народен Фронт 13-5 <br>
              1000 Скопје<br>
              Македонија <br><br>
              <strong>Телефонски број: </strong><a href="tel:+38923121314">+389 2 312 1314</a><br>
              <strong>Email: </strong><a href="mailto:coo@videoalarm.com.mk">coo@videoalarm.com.mk</a><br>
            </p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Корисни линкови</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <router-link to="/">Дома</router-link></li>
              <li><i class="bx bx-chevron-right"></i> <router-link to="/about">За нас</router-link></li>
              <li><i class="bx bx-chevron-right"></i> <router-link to="/support">Поддршка</router-link></li>
              <li><i class="bx bx-chevron-right"></i> <router-link to="/login">Најава</router-link></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Продукти</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <router-link to="/products/video">Системи за видео надзор</router-link></li>
              <li><i class="bx bx-chevron-right"></i> <router-link to="/products/alarms">Алармни системи</router-link></li>
              <li><i class="bx bx-chevron-right"></i> <router-link to="/products/access_control">Системи за контрола на пристап</router-link></li>
              <li><i class="bx bx-chevron-right"></i> <router-link to="/products/fire_alarm">Детекција и дојава на пожар</router-link></li>
              <li><i class="bx bx-chevron-right"></i> <router-link to="/products/work_time">Евиденција на работно време</router-link></li>
              <li><i class="bx bx-chevron-right"></i> <router-link to="/products/patrol">Системи за патролна и чуварска служба</router-link></li>
              <li><i class="bx bx-chevron-right"></i><router-link to="/products/doorbell">Домофонија и видеофонија</router-link></li>
              <li><i class="bx bx-chevron-right"></i><router-link to="/products/remote_gate">Далечинска контрола и автоматика</router-link></li>
              <li><i class="bx bx-chevron-right"></i><router-link to="/products/network">Мрежни системи</router-link></li>
              <li><i class="bx bx-chevron-right"></i><router-link to="/products/hotel">Хотелски системи</router-link></li>
              <!--<li><i class="bx bx-chevron-right"></i><a href="/products/smarthome">Smarthome системи</a></li>-->
              <li><i class="bx bx-chevron-right"></i><router-link to="/products/cables_other">Кабли и додатоци</router-link></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Социјални мрежи</h4>
            <p></p>
            <div class="social-links mt-3">
              <a href="https://www.facebook.com/videoalarmmk/" class="facebook" style="background-color: #3e4095; border-color: #3e4095;"><i class="bx bxl-facebook"></i></a>
              <a href="https://www.linkedin.com/company/video-alarm" class="linkedin" style="background-color: #3e4095; border-color: #3e4095;"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBar"
}
</script>

<style scoped>

</style>