<template>
  <main id="main" data-aos="fade-up">
    <section class="inner-page">
      <div class="container mt-5">
        <div class="container mt-5" data-aos="fade-up">
          <section id="faq" class="faq">
            <div class="container" data-aos="fade-up">
              <div class="section-title">
                <h2>F.A.Q</h2>
                <h3>Често поставени <span>Прашања</span></h3>
                <p>Некои од најчестите прашања кои се поставуваат</p>
              </div>

              <ul class="faq-list" data-aos="fade-up" data-aos-delay="100">

                <li>
                  <a data-toggle="collapse" class="collapsed" href="#faq1">Како се инсталира софтвер за LTS систем? <i class="icofont-simple-up"></i></a>
                  <div id="faq1" class="collapse border-bottom pt-2 pb-2" data-parent=".faq-list">
                    <h5>Посетете го наредниот линк </h5>
                    <a href="https://www.ltsecurityinc.com/downloads">ltsecurityinc.com/downloads</a>
                    <h5>Пристиснете на копчето за Client Software</h5><img class="img-fluid" src="../assets/img/faq/lts/lts-install-1.png"/>
                    <h5>Изберете ја опцијата за NVMS7000</h5><img class="img-fluid" src="../assets/img/faq/lts/lts-install-2.png"/>
                    <h5>Одкако ќе се спушти, со десен клик се одбира опцијата Extract all</h5><img class="img-fluid" src="../assets/img/faq/lts/lts-install-3.png"/>
                    <h5>Продолжете со чекорите за инсталација, илустрирани подолу</h5><img class="img-fluid" src="../assets/img/faq/lts/lts-install-4.png"/>
                    <img class="img-fluid" src="../assets/img/faq/lts/lts-install-5.png"/>
                    <img class="img-fluid" src="../assets/img/faq/lts/lts-install-6.png"/>
                    <img class="img-fluid" src="../assets/img/faq/lts/lts-install-7.png"/>
                    <h5>Одкако ќе заврши инсталацијата отворете го софтверот</h5><img class="img-fluid" src="../assets/img/faq/lts/lts-install-8.png"/>
                    <h5>Креирајте корисник и лозинка. <b>Лозинката е многу важна, ве молиме не ја заборавајте</b></h5><img class="img-fluid" src="../assets/img/faq/lts/lts-install-9.png"/>
                  </div>
                </li>

                <li>
                  <a data-toggle="collapse" href="#faq2" class="collapsed">Како да се додаде рекордер на LTS систем? <i class="icofont-simple-up"></i></a>
                  <div id="faq2" class="collapse border-bottom pt-2 pb-2" data-parent=".faq-list">
                    <h5>Одкако ќе го пуштите софтверот NVMS7000. Одберете го рекордерот во долниот прозорец и притиснете add to client</h5><img class="img-fluid" src="../assets/img/faq/lts/lts-rekorder-1.png"/>
                    <h5>Потоа на новоотворениот порзорец це додаваат потребните параметри. <b>Се корисат корисникот и лозинката од рекордерот</b></h5><img class="img-fluid" src="../assets/img/faq/lts/lts-rekorder-2.png"/>
                    <h5>Со притискање на копчето add, треба да се појави порака за успешно додавање.</h5><img class="img-fluid" src="../assets/img/faq/lts/lts-rekorder-3.png"/>
                    <h5>После додавање на рекордерот, во панелот control panel се одбира Main View.</h5><img class="img-fluid" src="../assets/img/faq/lts/lts-rekorder-4.png"/>
                    <h5>За да се пуштат камерите во живото, се притиска на новокреираната папка со двоен клик.</h5><img class="img-fluid" src="../assets/img/faq/lts/lts-rekorder-5.png"/>
                  </div>
                </li>

                <li>
                  <a data-toggle="collapse" href="#faq3" class="collapsed">Како се прегледува и вади снимка од LTS систем? <i class="icofont-simple-up"></i></a>
                  <div id="faq3" class="collapse border-bottom pt-2 pb-2" data-parent=".faq-list">
                    <h5 class="mb-3">Во софтверот за системот (NVMS7000) се протиска на Remote Playback</h5><img class="img-fluid mb-4" src="../assets/img/faq/lts/playback1.png"/>
                    <h5 class="mb-3">Во новоотворениот прозорец, се селектира календарот, потоа се одбира почетно и крајно време, и се притиска на копчето ОК</h5><img class="img-fluid mb-4" src="../assets/img/faq/lts/playback2.png"/>
                    <h5 class="mb-3">По одбирање на времето се притиска двапати на посакуваната камера. Снимката ќе се прикаже во прозорецот.</h5><img class="img-fluid mb-4" src="../assets/img/faq/lts/playback3.png"/>
                    <h5 class="mb-3">За да се превземе истата снимка. На прозорецот од снимката со десен клик се одбира downlaod.</h5><img class="img-fluid mb-4" src="../assets/img/faq/lts/backup1.png"/>
                    <h5 class="mb-3">Бо новоотворениот прозорец се одбира начинот и типот на снимката која што ке се превземе. И се притиска на копчето download.</h5><img class="img-fluid mb-4" src="../assets/img/faq/lts/backup2.png"/>
                  </div>
                </li>

                <li>
                  <a data-toggle="collapse" href="#faq4" class="collapsed">Како да се додаде рекордер на FOUTECH систем? <i class="icofont-simple-up"></i></a>
                  <div id="faq4" class="collapse border-bottom pt-2 pb-2" data-parent=".faq-list">
                    <h5 class="mb-3">Потребно е да имате инсталирано софтверот за FOUTECH системи - FOUTECH CMS (Може да се превземе инсталацијата подолу)<div class="container"><a class="btn btn-sm btn-primary mt-4 mb-4" href="../assets/downloads/foutechcms.exe">Инсталација за CMS</a></div> Одкако ќе го пуштите софтверот, во табот System се одбира Device Manager.</h5><img class="img-fluid mb-4" src="/assets/img/faq/foutech/foutecadd1.png"/>
                    <h5 class="mb-3">Во следниот прозорец се селектира опцијата add area.</h5><img class="img-fluid mb-4" src="../assets/img/faq/foutech/foutecadd2.png"/>
                    <h5 class="mb-3">Во новоотвореноит порзорец се именува зоната и се притиска OK.</h5><img class="img-fluid mb-4" src="../assets/img/faq/foutech/foutecadd3.png"/>
                    <h5 class="mb-3">Потоа се одбира креираната зона и се притиска add device.</h5><img class="img-fluid mb-4" src="../assets/img/faq/foutech/foutecadd4.png"/>
                    <h5 class="mb-3">Во новиот прозорец се именува уредот, се внесува IP адресата. Потоа се внесува корисничкото име и лозинка. И се притиска ОК.</h5><img class="img-fluid mb-4" src="../assets/img/faq/foutech/foutecadd5.png"/>
                    <h5 class="mb-3">Повторно се кликнува ОК.</h5><img class="img-fluid mb-4" src="../assets/img/faq/foutech/foutecadd6.png"/>
                    <h5 class="mb-3">Од левата срана ќе се појави името на уредот. Со двоен лев клик се поврзува на него.</h5><img class="img-fluid mb-4" src="../assets/img/faq/foutech/foutecadd7.png"/>
                    <h5 class="mb-3">Потоа на истиот уред се клика со десен клик, и во подменито се одбира Connect all video.</h5><img class="img-fluid mb-4" src="../assets/img/faq/foutech/foutecadd8.png"/>
                  </div>
                </li>

                <li>
                  <a data-toggle="collapse" href="#faq5" class="collapsed">Како се прегледува и вади снимка од FOUTECH систем? <i class="icofont-simple-up"></i></a>
                  <div id="faq5" class="collapse border-bottom pt-2 pb-2" data-parent=".faq-list">
                    <h5 class="mb-3">Во софтверот (CMS) со двоен лев клик се одбира рекордерот од левото мени. Потоа се притиска на копчето Playback.</h5><img class="img-fluid mb-4" src="../assets/img/faq/foutech/foutecplay1.png"/>
                    <h5 class="mb-3">Во новиот прозорец пред се се одбира рекордерот во полето device. Потоа се одбира опцијата да се пребарува по време(by time).<br> Во полето channel се одбира посакуваната камера и се притиска на копчето add (може да се одберат до 4 камери).<br> Во следното поле се одбира почетно и крајно време на снимката и потоа се притиска на копчето search. Во празниот простор ќе се појави снимката, и истата се пушта со двоен клик.</h5><img class="img-fluid mb-4" src="../assets/img/faq/foutech/foutecplay2.png"/>
                    <h5 class="mb-3">За превземање со лев клик се одбира снимката. И се притиска download.</h5><img class="img-fluid mb-4" src="../assets/img/faq/foutech/foutecplay3.png"/>
                  </div>
                </li>

                <li>
                  <a data-toggle="collapse" href="#faq6" class="collapsed">Како да се додаде рекордер на Milesight систем? <i class="icofont-simple-up"></i></a>
                  <div id="faq6" class="collapse border-bottom pt-2 pb-2" data-parent=".faq-list">
                    <h5 class="mb-3">По инсталација на Milesight CMS софтверот кој може да се пронајде на следниот линк.<a href="https://www.milesight.com/DownloadFile/Software/CMS/Milesight_CMS_2.4.0.10-r2.zip">Milesight CMS</a>Се отвара и се притиска на копчето за нов таб. Потоа се притиска на devices.</h5><img class="img-fluid mb-4" src="../assets/img/faq/milesight/milesightadd1.png"/>
                    <h5 class="mb-3">Во новоотворениот прозорец во полето за пребарување на нов уред, се одбира NVR. Потоа се притиска на копчето search, се селектира новонајдениот уред и во полето username и password се внесува корисничкото име и пасвордот, и се птитиска на копчето add.</h5><img class="img-fluid mb-4" src="../assets/img/faq/milesight/milesightadd2.png"/>
                    <h5 class="mb-3">Во табот live view, во горниот десен дел се одбира бројот на канали кој што ги има уредот.</h5><img class="img-fluid mb-4" src="../assets/img/faq/milesight/milesightadd3.png"/>
                    <h5 class="mb-3">Каде што е појавен новиот уред со десен клик се избира play</h5><img class="img-fluid mb-4" src="../assets/img/faq/milesight/milesightadd4.png"/>
                  </div>
                </li>

                <li>
                  <a data-toggle="collapse" href="#faq7" class="collapsed">Како се прегледува и вади снимка од Milesight систем? <i class="icofont-simple-up"></i></a>
                  <div id="faq7" class="collapse border-bottom pt-2 pb-2" data-parent=".faq-list">
                    <h5 class="mb-3">Во Milesight CMS софтверот се отвара нов таб и се притиска на playback.</h5><img class="img-fluid mb-4" src="../assets/img/faq/milesight/milesightplay1.png"/>
                    <h5 class="mb-3">Во новиот прозорец се избираат посакуваните камери. Во календарот се одбира датумот и се притиска на копчето search. Одкако ќе се појави лентата за снимениот материјал на самата лента со лев клик се одбира времето и потоа се стиска копчето play</h5><img class="img-fluid mb-4" src="../assets/img/faq/milesight/milesightplay1.png"/>
                    <h5 class="mb-3">За превземање на истите снимки се притиска на копчето clip(која има икона како ножици). Потоа на дадената лента се одбира почетно и крајно време и се притиска на копчето за превземање(стрелка надолу).</h5><img class="img-fluid mb-4" src="../assets/img/faq/milesight/milesightplay3.png"/>
                    <h5 class="mb-3">Во новиот прозорец се одбираат материјалите кои сакате да ги превземете и се притиска на копчето download selected.</h5><img class="img-fluid mb-4" src="../assets/img/faq/milesight/milesightplay4.png"/>
                  </div>
                </li>

                <li>
                  <a data-toggle="collapse" href="#faq8" class="collapsed">Упатство за користење на Crow систем. <i class="icofont-simple-up"></i></a>
                  <div id="faq8" class="collapse border-bottom pt-2 pb-2" data-parent=".faq-list">
                    <h5 class="mb-3"><a href="../assets/downloads/PW-16.pdf">PW-16</a></h5>
                  </div>
                </li>

                <!--<li>
                  <a data-toggle="collapse" href="#faq4" class="collapsed">Ac odio tempor orci dapibus. Aliquam eleifend mi in nulla? <i class="icofont-simple-up"></i></a>
                  <div id="faq4" class="collapse" data-parent=".faq-list">
                    <p>
                      Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                    </p>
                  </div>
                </li>
                -->
              </ul>
            </div>
          </section>

          <section id="services" class="services">
            <div class="container" data-aos="fade-up">

              <div class="section-title">
                <h3><span>Tехничка поддршка</span></h3>
              </div>

              <div class="row">

                <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                  <div class="icon-box">
                    <div class="icon"><i class="bx bx-lock-open"></i></div>
                    <h4><a href="">kosta@videoalarm.com.mk</a></h4>
                    <p>Поддршка за контрола на пристап</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                  <div class="icon-box">
                    <div class="icon"><i class="bx bx-cctv"></i></div>
                    <h4><a href="">kire@videoalarm.com.mk</a></h4>
                    <p>Поддршка за CCTV и алармни системи</p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                  <div class="icon-box">
                    <div class="icon"><i class="bx bx-envelope-open"></i></div>
                    <h4><a href="">support@videalarm.com.mk</a></h4>
                    <p>E-mail за останата поддршка</p>
                  </div>
                </div>

              </div>
            </div>
          </section>

        </div>
      </div>
    </section>
  </main><!-- End #main -->
</template>

<script>
export default {
  name: "SupportPage",
  mounted() {
    document.title = "Support"
  }
}
</script>

<style scoped>

</style>