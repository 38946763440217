<template>
  <main id="main" data-aos="fade-up">
    <HomePage/>
  </main>
</template>

<script>
import HomePage from "@/components/HomePage";
export default {
  name: "LandingPage",
  components: {
    HomePage
  }
}
</script>

<style scoped>

</style>