<template>
  <main id="main" data-aos="fade-up" class="mt-5">
  <section id="about" class="about section-bg mt-lg-5">
    <div class="container mt-5" data-aos="fade-up">
      <div class="section-title">
        <h2>За Нас</h2>
        <h3>Дознајте повеќе  <span>За нас</span></h3>
        <p>Компанијата “ВИДЕО АЛАРМ” успешно делува и работи на полето на сигурносно-техничката заштита на објекти и отворени простори повеќе од 25 и е една од водечките компании во тој домен во Македонија.</p>
      </div>

      <div class="row">
        <div class="col-lg-6" data-aos="zoom-out" data-aos-delay="100">
          <img src="../assets/img/about.jpg" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
          <p>
            Како специјализирана компанија чија основна и единствена дејност е проектирање, набавка и изведба на системи за Видео Надзор- локален и далечински , ПП Системи, системи за Контрола на Пристап и Евиденција на работно време, системи за Детекција и дојава на провала и кражба и Видео-домофонски и системи за интерфонска комуникација, Автоматски бариери за возила и автоматика за врати, изведуваме и во состојба сме да понудиме комплетни решенија за истите.
          </p>
          <p>
            Во своето долгогодишно работење нашата компанија се одликува со професионална и брза изведба дадените задачи и договорените работи. Сето тоа е допринес од постојаната опременост на компанијата со потребните компоненти за системите - како постојан лагер на опремата за системите што ги нуди и располагањето на компанијата со стручен кадар од 8 вработени лица од кои 7 лица се технички оспособени и се директно вклучени во проектирањето и изведбата на системите.
          </p>
        </div>
      </div>
    </div>
  </section><!-- End About Section -->
    <!-- ======= Counts Section ======= -->
    <section id="counts" class="counts section-bg">
      <div class="container" data-aos="fade-up">

        <div class="row">

          <div class="col-lg-3 col-md-6">
            <div class="count-box">
              <i class="icofont-simple-smile"></i>
              <span data-toggle="counter-up"><span>500+</span></span>
              <p>Задоволни клиенти</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
            <div class="count-box">
              <i class="icofont-document-folder"></i>
              <span data-toggle="counter-up"><span>1500+</span></span>
              <p>Реализирани проекти</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div class="count-box">
              <i class="icofont-live-support"></i>
              <span data-toggle="counter-up">365</span>
              <p>Дена поддршка</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div class="count-box">
              <i class="icofont-users-alt-5"></i>
              <span data-toggle="counter-up"><span>8</span></span>
              <p>Вработени</p>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Counts Section -->

    <!-- ======= Clients Section ======= -->
    <section id="clients" class="clients section-bg">
      <div class="container" data-aos="zoom-in">

        <div class="row">

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/client-1.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/client-2.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/client-3.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/client-4.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/client-5.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/kargo.jpg" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/adora.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/amsm.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/autosk.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/flamingo.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/hitech.png" class="img-fluid" alt="">
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src="../assets/img/clients/info.png" class="img-fluid" alt="">
          </div>

        </div>

      </div>
    </section>
    <!-- End Clients Section -->
  </main>
</template>

<script>
export default {
  name: "AboutPage",
  mounted() {
    document.title = "About";
  }
}
</script>

<style scoped>

</style>