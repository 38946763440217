<template>
<main id="main" data-aos="fade-up" class="mt-5">
  <!-- ======= Team Section ======= -->
  <section id="team" class="team section-bg mt-3">
    <div class="container mt-5" data-aos="fade-up">

      <div class="section-title">
        <h2>Партнери</h2>
        <h3>Копмпании со кои <span>Соработуваме</span></h3>
        <p>Тука се наброени некои од компаниите со кои ние соработуваме.</p>
      </div>

      <div class="row">

        <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
          <div class="member">
            <div class="member-img">
              <img src="../assets/img/team/crow.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href="https://www.thecrowgroup.com/"><i class="icofont-web"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>CROW</h4>
              <span>Electronic Engineering</span>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
          <div class="member">
            <div class="member-img">
              <img src="../assets/img/team/milesight.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href="https://www.milesight.com/"><i class="icofont-web"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>Milesight</h4>
              <span></span>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
          <div class="member">
            <div class="member-img">
              <img src="../assets/img/team/foutec.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href="http://www.foutec.com/"><i class="icofont-web"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>Foutec</h4>
              <span>Digital Technology</span>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
          <div class="member">
            <div class="member-img">
              <img src="../assets/img/team/paili.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href="http://www.pailitech.com/"><i class="icofont-web"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>Paili</h4>
              <span>Pailitech</span>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
          <div class="member">
            <div class="member-img">
              <img src="../assets/img/team/idteck.png" class="img-fluid" alt="">
              <div class="social">
                <a href="https://www.idteck.com/en/"><i class="icofont-web"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>IDTECK</h4>
              <span></span>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
          <div class="member">
            <div class="member-img">
              <img src="../assets/img/team/ygs.jpg" class="img-fluid" alt="">
              <div class="social">
                <a href=""><i class="icofont-web"></i></a>
              </div>
            </div>
            <div class="member-info">
              <h4>YGS Locks</h4>
              <span></span>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section><!-- End Team Section -->
</main>
</template>

<script>
export default {
  name: "PartnerPage",
  mounted() {
    document.title = "Partners"
  }
}
</script>

<style scoped>

</style>