<template>
  <main id="main" class="pt-5 mt-5">
  <div class="container" data-aos="fade-up">

    <section id="about" class="about section-bg">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>{{product.brand}}</h2>
          <h3>{{product.name}}</h3>
        </div>

        <div class="row">
          <div class="col-lg-6" data-aos="zoom-out" data-aos-delay="100">
            <img v-bind:src="'' + product.image" class="img-fluid" alt="">
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
            <p>{{product.description}}</p>
          </div>
        </div>

      </div>
    </section>
  </div>
  </main>
</template>

<script>
import { useRoute } from 'vue-router';

export default {
  name: "DetailsPage",
  data(){
    return{
      product:{},
      id: 1
    }
  },
  mounted() {
    const route = useRoute()
    this.id = route.params.id
    this.getProduct()
  },
  methods:{
    async getProduct(){
      const baseUrl = process.env.VUE_APP_BASEURL
      const res = await fetch(baseUrl + "/details.php?id=" +  this.id)
      this.product = await res.json()
    }
  }
}
</script>

<style scoped>

</style>