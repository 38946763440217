<template>
  <main id="main" data-aos="fade-up">

    <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>{{ category.name }}</h2>
          <ol>
            <li><a href="/home">Дома</a></li>
            <li><a href="/products">Продукти</a></li>
            <li>{{ category.name }}</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->
    <div class="container-flex pl-5 pr-5 d-flex justify-content-center" data-aos="fade-up">
      <section id="team" class="team section-bg pt-1 pb-1 w-75">
        <div class="container row" data-aos="fade-up">
          <div class="container col-lg-2">
            <div class="list-group" style="background-color: #f6f9fe">
              <a class="pr-1 pb-1 list-group-item list-group-item-secondary list-group-item-action" v-for="sub in category.subs" :key="sub.suburl" v-bind:href="'/products/' + category.url + '/' + sub.suburl"><small>{{ sub.subname }}</small></a>
            </div>
          </div>
          <div class="col">
          <PagesBase :products = "displayItems"/>

          <ul class="pagination justify-content-center">
            <li class="page-item"><a class="page-link" v-on:click="updatePage(this.currentPage-1)"><i class="bx bx-arrow-to-left"></i></a></li>
            <li class="page-item" v-for="i in maxPages" :key="i">
              <div v-if="checkPage(i)" class="page-link bg-secondary"><a class="text-light" v-on:click="updatePage(i-1)">{{i}}</a></div>
              <div v-else class="page-link"><a class="text-dark" v-on:click="updatePage(i-1)">{{i}}</a></div>
            </li>
            <li class="page-item"><a class="page-link" v-on:click="updatePage(this.currentPage+1)"><i class="bx bx-arrow-to-right"></i></a></li>
          </ul></div>
        </div>
      </section>
    </div>
  </main><!-- End #main -->
</template>

<script>
import  {useRoute} from 'vue-router';
import PagesBase from "@/components/PagesBase";

export default {
  name: "ProductCategory",
  components: {
    PagesBase
  },
  data(){
    return {
      categories:[
        {
          url: "alarms",
          name: "Алармни системи",
          subs: [
            {
              suburl: "alarm_cpanel",
              subname: "Централи"
            },
            {
              suburl: "alarm_keypad",
              subname: "Тастатури и шифратори"
            },
            {
              suburl: "alarm_siren",
              subname: "Сирени"
            },
            {
              suburl: "alarm_wiredsensor",
              subname: "Сензори-жичани"
            },
            {
              suburl: "alarm_wireless",
              subname: "Бежични аларми"
            },
            {
              suburl: "alarm_addons",
              subname: "Додатоци"
            }
          ]
        },
        {
          url: "fire_alarm",
          name: "Детекција и дојава на пожар",
          subs: [
            {
              suburl: "pp_addressable",
              subname: "Адресабилни ПП системи"
            },
            {
              suburl: "pp_conventional",
              subname: "Конвенционални ПП системи"
            }
          ]
        },
        {
          url: "hotel",
          name: "Хотелски системи",
          subs: []
        },
        {
          url: "network",
          name: "Мрежни системи",
          subs: []
        },
        {
          url: "video",
          name: "Системи за видео надзор",
          subs: [
            {
              suburl: "analog_camera",
              subname: "Аналог и аналог HD камери"
            },
            {
              suburl: "ahd_dvr",
              subname: "AHD дигитал видео рекордери"
            },
            {
              suburl: "iphd_camera",
              subname: "IP-HD камери"
            },
            {
              suburl: "nvr",
              subname: "Мрежни дигитал видео рекордери"
            },
            {
              suburl: "special_camera",
              subname: "Специјални камери"
            },
            {
              suburl: "microphone",
              subname: "Микрофони"
            },
            {
              suburl: "video_addons",
              subname: "Додатоци"
            }
          ]
        },
        {
          url: "access_control",
          name: "Контрола на пристап",
          subs: [
            {
              suburl: "network_controllers",
              subname: "Мрежни контролери за контрола на пристап"
            },
            {
              suburl: "converters",
              subname: "Конвертори"
            },
            {
              suburl: "independent_controllers",
              subname: "Самостојни RFID контролери"
            },
            {
              suburl: "readers",
              subname: "Читачи"
            },
            {
              suburl: "rfid_cards",
              subname: "RFID картички и привезоци"
            },
            {
              suburl: "electronic_locks",
              subname: "Електромагнетни и електрични брави"
            },
            {
              suburl: "remotes",
              subname: "Тастери и далечински контролери"
            }
          ]
        },
        {
          url: "doorbell",
          name: "Домофонија и видеофонија",
          subs: [
            {
              suburl: "2_wire_outside",
              subname: "2 жични надворешни единици"
            },
            {
              suburl: "2_wire_inside",
              subname: "2 жични внатрешни единици"
            },
            {
              suburl: "2_wire_addons",
              subname: "Додатоци за 2 жични видеофони"
            },
            {
              suburl: "4_wire_outside",
              subname: "4 жични надворешни единици"
            },
            {
              suburl: "4_wire_inside",
              subname: "4 жични внатрешни единици"
            },
            {
              suburl: "addressable_outside",
              subname: "Адресабилни надворешни единици"
            },
            {
              suburl: "addressable_inside",
              subname: "Адресабилни внатрешни единици"
            },
            {
              suburl: "addressable_addons",
              subname: "Додатоци за адресабилни видеофони"
            },
            {
              suburl: "doorbells",
              subname: "Домофонија"
            }
          ]
        },
        {
          url: "cables_other",
          name: "Кабли и додатоци",
          subs: [
            {
              suburl: "cable",
              subname: "Кабли"
            },
            {
              suburl: "addons",
              subname: "Додатоци"
            },
            {
              suburl: "batandpower",
              subname: "Батерии и напојни единици"
            },
            {
              suburl: "other",
              subname: "Останато"
            }
          ]
        },
        {
          url: "patrol",
          name: "Системи за патролна и чуварска служба",
          subs: []
        },
        {
          url: "remote_gate",
          name: "Далечинска контрола и автоматика",
          subs: [
            {
              suburl: "remote_systems",
              subname: "Системи за далечинкса контрола"
            },
            {
              suburl: "parking_systems",
              subname: "Паркинг системи"
            },
            {
              suburl: "auto_barriers_tripods",
              subname: "Автоматски бариери и триподи"
            },
            {
              suburl: "auto_gates",
              subname: "Автоматика за капии и врати"
            }
          ]
        },
        {
          url: "work_time",
          name: "Евиденција на работно време",
          subs: [
            {
              suburl: "wt_systems",
              subname: "Системи за евиденција на работно време"
            },
            {
              suburl: "idteck",
              subname: "IDTECK системи"
            },
            {
              suburl: "software",
              subname: "Софтвери за евиденција на работно време и контрола на пристап"
            },
            {
              suburl: "converters",
              subname: "Конвертори"
            },
            {
              suburl: "readers",
              subname: "Дополнителни читачи"
            }
          ]
        }
      ],
      category: '',
      products: [
        {
          id: 1,
          name: "Test",
          brand: "Brand",
          image: "/assets/img/products/alarm/302.jpg"
        },
        {
          id: 2,
          name: "Test",
          brand: "Brand",
          image: "/assets/img/products/alarm/302.jpg"
        },
        {
          id: 2,
          name: "Test",
          brand: "Brand",
          image: "/assets/img/products/alarm/302.jpg"
        },
        {
          id: 2,
          name: "Test",
          brand: "Brand",
          image: "/assets/img/products/alarm/302.jpg"
        },
        {
          id: 2,
          name: "Test",
          brand: "Brand",
          image: "/assets/img/products/alarm/302.jpg"
        },
        {
          id: 2,
          name: "Test",
          brand: "Brand",
          image: "/assets/img/products/alarm/302.jpg"
        }
      ],
      prods: [],
      displayItems: [],
      currentPage: 0,
      maxPages: 1,
      itemsPerPage: 20
    }
  },
  mounted() {
    const route =useRoute();
    //this.category=route.params.category;
    this.categories.forEach(cat => {
      if (cat.url === route.params.category){
        this.category=cat;
      }
    });
    this.getProds();
    console.log(this.prods)
    document.title = this.category.name;
  },
  methods: {
    async getProds(){
      const baseUrl = process.env.VUE_APP_BASEURL
      const res = await fetch(baseUrl + "/categories.php?id=" + this.category.url)
      this.prods = await res.json()
      this.updateDisplayProds()
      this.maxPages = Math.ceil(this.prods.length / this.itemsPerPage)
    },
    updateDisplayProds(){
      this.displayItems = this.prods.slice(this.currentPage * this.itemsPerPage, (this.currentPage * this.itemsPerPage) + this.itemsPerPage);
    },
    updatePage(pageNumber){
      if(pageNumber >= 0 && pageNumber < this.maxPages){
        this.currentPage = pageNumber
        this.updateDisplayProds()
      }
    },
    checkPage(i){
      return i === this.currentPage+1;
    }
  }
}
</script>

<style scoped>

</style>