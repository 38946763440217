<template>
  <div id="topbar" class="d-none d-lg-flex align-items-center fixed-top">
    <div class="container d-flex">
      <div class="contact-info mr-auto align-middle">
        <i class="icofont-envelope align-bottom"></i> <a class="align-bottom" href="mailto:coo@videoalarm.com.mk">coo@videoalarm.com.mk</a>
        <i class="icofont-phone align-bottom"></i> <a href="tel:+38923121314" class="align-bottom">+389 2 312 1314</a>
      </div>
      <div class="row justify-content-end align-middle">
        <div class="container justify-content-end">
          <form action="/search" method="get" class="search-form">
            <input v-model="keyword" type="text" class="form-control form-control-sm mr-2" name="keyword" id="keyword" placeholder="пребарување">
          </form>
        </div>
      </div>
      <div class="social-links align-middle"> <!--
            <a href="#" class="dns"><img src="/assets/img/mkflag.png" class="img-fluid" style="width: 25px"></a>
            <a href="#" class="dns"><img src="/assets/img/gbflag.png" class="img-fluid" style="width: 25px"></a>
        <a href="/docs" class="dns align-bottom"><i class="icofont-ui-user"></i></a> -->
        <a href="https://www.facebook.com/videoalarmmk/" class="facebook align-bottom"><i class="icofont-facebook"></i></a>
        <a href="https://www.linkedin.com/company/video-alarm" class="linkedin align-bottom"><i class="icofont-linkedin"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  data(){
    return{
      keyword: ""
    }
  }
}
</script>

<style scoped>
</style>