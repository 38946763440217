<template>
  <TopBar/>
  <HeaderBar/>
  <router-view></router-view>
  <FooterBar/>
</template>

<script>
import TopBar from "@/components/TopBar";
import HeaderBar from "@/components/HeaderBar";
import FooterBar from "@/components/FooterBar";
export default {
  name: 'App',
  components: {
    TopBar,
    HeaderBar,
    FooterBar
  },
}
</script>

<style>

</style>
